'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitsStatusManager

 # @description

###
angular
  .module 'mundoAdmin.units'
  .factory 'UnitsStatusManager', [
    'MundoDefaultManager'
    (MundoDefaultManager) ->
      UnitsStatusManagerBase  =  new MundoDefaultManager()
      UnitsStatusManagerBase.conf.url = 'units/statuses'

      UnitsStatusManagerBase
  ]
